import React from "react";
import styled from "styled-components";
import withLayout from "../../layout";
import {FormattedMessage, injectIntl} from "react-intl";
//COMPONENTS
import SimpleStats from "../../components/UI/SimpleStats";
import Image from "../../components/UI/Image";
//GENERIC STYLE IMPORT
import { Heading, Paragraph } from "../../components/UI/Typography";
import DropBottom from '../../images/case_studies/bancaAnagni/DropBottom.svg';
import DropUp from '../../images/case_studies/bancaAnagni/DropUp.svg';
import BKGImg from "../../images/case_studies/bancaAnagni/Banca-anagni-banner 1920x1080.jpg";
import HeroCaseStudy from "../../components/HeroCaseStudy";
import LottieController from "../../components/UI/LottieController";
import { useInView } from "react-intersection-observer";
import DropBg from "../../components/UI/DropBg";
import DropBig1 from '../../images/case_studies/acea/DropBig1.svg';
import DropBig2 from '../../images/case_studies/acea/DropTopLeftM.svg';
import SEO from "../../components/SEO";
import Result1 from '../../images/case_studies/bancaAnagni/Scudo BA.json';
import Result2 from '../../images/case_studies/bancaAnagni/Banca Anagni +270.json';
import {Fade} from 'react-reveal';
import FullWidthMessage from "../../components/CaseStudy/FullWidthMessage";
import NextSuccessStory from "../../components/CaseStudy/NextSuccessStory";
import SectionTitle from "../../components/CaseStudy/SectionTitle";
import Results from "../../components/CaseStudy/Results";
import FullWidthImage from "../../components/CaseStudy/FullWidthImage"

const StatSection = styled.div`
padding-top:32px;
h1{
font-weight: bold;
font-size: 80px;
letter-spacing: 2.9px;
color:#1D0F41;
}
h2{
font-weight: bold;
font-size: 50px;
letter-spacing: 1.82px;
color: #2266A4;
}
p{
    font-weight:bold;
    font-size:18px
    letter-spacing:0.39px;
    color: #2266A4;

}
`

const StatsDiv = styled.div`
margin-top: 32px;
h3{
color:#1D0F41;
font-weight:700;
font-style:normal;
font-size:32px;
margin:0px;
}
p{
    color:#2266A4;
    font-size:18px;
    font-weight:700;
}

`

const CompanyStats = () => {
    return <StatsDiv>
        <h3>37.000+ </h3>
        <p style={{color:'#E9A033'}}>
            <FormattedMessage id={'banca_anagni.stats_1_msg'} />
        </p>
    </StatsDiv>
}

const BancaAnagniCaseStudy = ({intl}) => {
    const [graphView1, inViewG1] = useInView();
    const [graphView3, inViewG3] = useInView();
    const keywords = intl.formatMessage({id:'banca_anagni.meta_keywords'}).split(',') || [];

    return (
        <>
            <SEO title={intl.formatMessage({id: 'banca_anagni.meta_title'})} description={intl.formatMessage({id: 'banca_anagni.meta_description'})} keywords={keywords} lang={intl.locale}/>
            <HeroCaseStudy hasPartner={false} heroImage={'case_studies/bancaAnagni/Header-Banca-Anagni.png'} title={'banca_anagni.title'} intl={intl}
                           stats={<CompanyStats />} subtitle1={'banca_anagni.subtitle'} color={'linear-gradient(90deg, #E9A033 0%, #FFDD87 100%)'}
                           categories={['portfolio.filters.design_dev']}/>
            <div className="section-padding">
                <DropBg src={DropBig1} left={'0%'} zIndex={'-1'} bottom={'-20%'} className="d-none d-lg-block" />
                <DropBg src={DropUp} bottom={'5%'} left={'25%'} className="d-none d-lg-block" />
                <div className="container">
                    <div className="row">
                        <Fade bottom delay={200}>
                            <div className="col-12">
                                <SectionTitle number={'01'} text={'banca_anagni.overview_title'}/>
                            </div>
                        </Fade>
                    </div>
                    <div className="row">
                        <div className={"col-md-6 col-12"}>
                            <Fade bottom delay={300}>
                                <Heading level={"3"}>
                                    <FormattedMessage id={"banca_anagni.client_title"}/>
                                </Heading>
                            </Fade>
                            <Fade bottom delay={500}>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"banca_anagni.client_1"}/>
                                </Paragraph>
                            </Fade>
                        </div>
                        <div className={"col-12 col-md-5 offset-md-1"}>
                            <Fade bottom delay={700}>
                                <Heading level={"4"} className="pt-2">
                                    <FormattedMessage id={"banca_anagni.client_needs"}/>
                                </Heading>
                            </Fade>
                            <Fade bottom delay={900}>
                                <Paragraph className="pt-3">
                                    <FormattedMessage id={"banca_anagni.client_needs_1"}/>
                                </Paragraph>
                                <StatSection>
                                    <h1> 49,1</h1>
                                    <h2 style={{marginBottom: 16, color: '#E9A033'}}><FormattedMessage
                                        id={'banca_anagni.sales_1'}/></h2>
                                    <p style={{color: '#E9A033'}}><FormattedMessage id={'banca_anagni.sales_2'}/></p>
                                </StatSection>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
            <FullWidthImage image={BKGImg} imageWidth={1920} imageHeight={1080}/>
            <div className="section-padding">
                <DropBg src={DropBig2} right={'-10%'} zIndex={'-1'} top={'15%'} className="d-none d-lg-block" />
                <DropBg src={DropBottom} top={'10%'} right={'10%'} className="d-none d-lg-block" />
                <Fade bottom delay={400}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 d-none d-md-block">
                                <SectionTitle number={'02'} text={'banca_anagni.brief_title'}/>
                            </div>
                            <div className="col-md-8 col-xs-12">
                                <Heading level={"2"} margin={'0px'}>
                                    <FormattedMessage id={"banca_anagni.service_big_title"} />
                                </Heading>
                                <Paragraph className="pt-2">
                                    <FormattedMessage id={"banca_anagni.services_txt_1"} />
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className="d-flex align-items-center">

                    <div className="container">
                        <div className="row">
                            <Fade bottom delay={200}>
                                <div className="col-12">
                                    <SectionTitle number={'03'} text={'banca_anagni.workflow_title'}/>
                                </div>
                            </Fade>
                        </div>
                        <div className="row ">
                            <Fade bottom delay={400}>
                                <div className="col-md-7 col-sm-12">
                                    <Heading level={"3"}>
                                        <FormattedMessage id={"banca_anagni.solutions_big_title"} />
                                    </Heading>
                                    <Paragraph>
                                        <FormattedMessage id={"banca_anagni.solutions_txt_1"} />
                                    </Paragraph>
                                    <Heading level={"4"}>
                                        <FormattedMessage id={"banca_anagni.solutions_title_2"} />
                                    </Heading>
                                    <Paragraph>
                                        <FormattedMessage id={"banca_anagni.solutions_txt_2"} />
                                    </Paragraph>
                                </div>
                            </Fade>
                            <Fade bottom delay={800}>
                                <div className="col-md-4 col-sm-12 offset-0 offset-md-1 position-relative">
                                    <Image filename={'case_studies/bancaAnagni/Body-banca-anagni1.jpg'} alt={'body banca Anagni'} className={'case-study-right-pic'}/>
                                </div>
                            </Fade>
                        </div>
                    </div>

            </div>
            <div className="section-padding-custom">
                <div className="container">
                    <div className="row align-items-start">
                        <Fade bottom delay={400}>
                            <div className="col-lg-5 col-sm-12 order-last order-lg-first d-lg-block d-none position-relative">
                                <Image
                                    filename="case_studies/bancaAnagni/Banca-Anagni-@X2.jpg"
                                    alt="bancAnagni"
                                    className="case-study-left-pic"
                                />
                            </div>
                        </Fade>
                        <Fade bottom delay={800}>
                            <div className="col-12 col-lg-6 offset-lg-1 order-first order-lg-last">
                                <Heading level={"4"}>
                                    <FormattedMessage id={"banca_anagni.solutions_title_3"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"banca_anagni.solutions_txt_3"}/>
                                </Paragraph>
                                <Heading level={"4"}>
                                    <FormattedMessage id={"banca_anagni.solutions_title_4"}/>
                                </Heading>
                                <Paragraph>
                                    <FormattedMessage id={"banca_anagni.solutions_txt_4"}/>
                                </Paragraph>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            <Results
                subtitle="banca_anagni.dark_section_paragraph"
                testimonialQuote="banca_anagni.dark_section_quote"
                testimonialImg="carlo_angeletti.jpg"
                testimonialName="banca_anagni.dark_section_testimonial_name"
                testimonialRole="banca_anagni.dark_section_testimonial_desc"
            >
                <div className="col-12 col-lg-3">
                    <div ref={graphView1}>
                        <LottieController
                            lottieJSON={Result1}
                            inView={inViewG1}
                            height="100px"
                            width="86px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            inView={inViewG1}
                            valueFrom={0}
                            valueTo={700}
                            prefix="+"
                            headingStyle={{fontWeight: 500}}
                            label="banca_anagni.stats_1"
                            headingLevel="1"
                            labelColor="white"
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div ref={graphView3}>
                        <LottieController
                            lottieJSON={Result2}
                            inView={inViewG3}
                            height="98px"
                            width="208px"
                            loop={false}
                        />
                        <SimpleStats
                            labelWidth="250px"
                            prefix="+"
                            inView={inViewG3}
                            valueFrom={0}
                            valueTo={270}
                            measureUnit="%"
                            label="banca_anagni.stats_2"
                            headingLevel="1"
                            labelColor="white"
                            headingStyle={{fontWeight: 500}}
                        />
                    </div>
                </div>
            </Results>
            <FullWidthMessage isBig intl={intl}/>
            <NextSuccessStory
                title="banca_anagni.next_success_case_title"
                desc="banca_anagni.next_success_case_description"
                link="/case-study/insieme/"
                intl={intl}
            />
        </>
    );
};

const customProps = {
    localeKey: "banca_anagni", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(BancaAnagniCaseStudy));
